@import "../../styles/theme.scss";

/* NAV BAR STYLES */

.nav_section_large {
  color: $greyishTeal;
}

.nav_section_large:hover {
  cursor: pointer;
  color: $greyishTeal;
}

.nav_section_link {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  color: $greyishTeal;
}

.nav_section_link:hover {
  cursor: pointer;
  color: $greyishTeal;
}

.large_cell {
  display: flex;
  flex: 2;
  align-items: center;
  flex-direction: row;
}

.zip_cell {
  display: flex;
  flex-grow: 2;
  align-items: center;
}

.table_font {
  font-family: "Lato";
  color: $warmGrey;
}

.table_header {
  display: flex;
  justify-content: space-evenly;
  min-height: 25px;
  background-color: $lightGrey;
}

.table_header_cell {
  display: flex;
  align-items: center;
  flex: 1;
  padding: 1.5pt;
  padding-left: 10pt;
}

.table_header_cell_food {
  @extend .table_header_cell;
  min-width: 140pt;
}

.table_row {
  display: flex;
  height: 26.5pt;
  flex-grow: 1;
  border-bottom: 1px solid $lightGrey;
  height: auto;
}

.table_cell_action {
  display: flex;
  flex: 1;
  padding: 10pt;
  align-items: center;
  justify-content: center;
  border-left: 1px solid $lightGrey;
  border-right: 1px solid $lightGrey;
}

.table_cell_action:hover {
  background: $lightGrey;
}

.table_row_action {
  display: flex;
  flex: 1;
}

.user_account_row {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.modal_message {
  display: flex;
  height: 30pt;
  color: white;
  background-color: #f0ad4e;
  border-top-left-radius: 1pt;
  border-top-right-radius: 1pt;
  align-items: center;
  padding-left: 10pt;
}

.modal_message_success {
  @extend .modal_message;
  background-color: $greyishTeal;
}

.modal_message_error {
  @extend .modal_message;
  background-color: #bb2124;
}

/* TIME SCHEDULING */
.time_scheduling {
  width: 80%;
  margin-bottom: 1.2rem;
}

.time_scheduling_row {
  display: flex;
}

.time_scheduling_hours {
  display: flex;
  flex: 1;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  font-size: 1rem;
  font-family: "Lato";
  font-weight: "bold";
  justify-content: center;
  align-items: center;
}

.time_scheduling_hours input[type="checkbox"] {
  background-color: black;
}

.time_scheduling_header {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-family: "Lato";
  font-weight: bold;
}

.time_scheduling_sub_header {
  margin-bottom: 3rem;
  font-size: 1rem;
  font-family: "Lato";
  color: $greyishBrown;
}

.time_scheduling_note {
  margin-bottom: 0.8rem;
}

.time_scheduling_time_label {
  @extend .time_scheduling_hours;
  justify-content: center;
}

.time_range_container {
  display: flex;
  flex-direction: row;
  height: 70pt;
  align-items: center;
}

.time_range_block {
  display: flex;
  width: 40pt;
  height: 50pt;
  align-items: center;
  justify-content: center;
}

.time_range_block_input {
  @extend .time_range_block;
  width: 60pt;
}

.table_header_group_action_container {
  display: flex;
  align-items: center;
  flex: 1;
  color: white;
  font-size: medium;
  font-family: "Lato";
  justify-content: space-between;
}

.table_header_action_button {
  background-color: $greyishTeal;
  border-radius: 55pt;
  display: flex;
  width: 80pt;
  font-size: smaller;
  color: white;
  font-family: "Lato";
  align-items: center;
  justify-content: center;
}

.table_header_action_button_deny {
  @extend .table_header_action_button;
  background-color: red;
}

.table_header_action_button:hover {
  cursor: default;
  color: $greyishTeal;
  background-color: white;
}

.table_header_action_button_deny:hover {
  color: red;
}

.table_header_cell_group {
  display: flex;
  padding-right: 10pt;
  align-items: center;
  flex: 1;
}

.table_header_group_action_cell {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.meredian_div {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Lato";
  font-size: 1rem;
  color: $warmGrey;
}

.time_scheduling_hours_div {
  display: flex;
  color: white;
  background-color: white;
}

.create_buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: "Lato";
  font-size: 1rem;
  color: $warmGrey;
}

.right_delete_button_container {
  margin-right: 10pt;
  margin: 5px 0;
  display: flex;
  justify-content: flex-end;
}

.edit-icon {
  color: $greyishTeal;
}

.modal-checkbox {
  margin-right: 10pt;
}

.modal-button-confirm {
  background-color: $greyishTeal !important;
}

.modal-button-cancel {
  background-color: $warmGrey !important;
}

.modal-description {
  margin: 20pt;
  margin-bottom: 5pt;
}

.time-window-name {
  margin-top: 1em !important;
}

.offers_header {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-family: "Lato";
  font-weight: bold;
}

.offers_sub_header {
  margin-bottom: 3rem;
  font-size: 1rem;
  font-family: "Lato";
  color: $greyishBrown;
}

.offers_checkbox_label_container {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 10;
}

.offers_checkbox_label {
  width: 80px;
  margin-top: 3px;
  font-size: 1rem;
  font-family: "Lato";
  font-weight: "bold";
  color: black;
}

.offers_checkbox_label_info {
  margin-top: 3px;
  font-size: 1rem;
  font-family: "Lato";
  font-weight: "bold";
  color: $greyishBrown;
}

.offers_textbox_label_info {
  margin-bottom: 3px;
  font-size: 12px;
  font-family: "Lato";
  font-weight: "bold";
  color: $greyishBrown;
}

.partners_header {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-family: "Lato";
  font-weight: bold;
}

.partners_sub_header {
  margin-bottom: 3rem;
  font-size: 1rem;
  font-family: "Lato";
  color: $greyishBrown;
}

.partners_checkbox_label_container {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 10;
}

.partners_checkbox_label {
  width: 120px;
  margin-top: 3px;
  font-size: 1rem;
  font-family: "Lato";
  font-weight: "bold";
  color: black;
}

.partners_checkbox_label_info {
  margin-top: 3px;
  font-size: 1rem;
  font-family: "Lato";
  font-weight: "bold";
  color: $greyishBrown;
}

.actionBox{
  display: flex;
  justify-content: flex-end;
}