@import "../../styles/theme.scss";

/* NAV BAR */

.nav_section_link {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  color: $greyishTeal;
}

.nav_section_link:hover {
  cursor: pointer;
  color: $greyishTeal;
}

/* TABLE */
.icon {
  width: 20px;
  margin: 5px;
}

.large_cell {
  display: flex;
  flex: 2;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
}

.name_cell {
  display: flex;
  flex: 5;
}

.user_cell {
  display: flex;
  flex: 1;
  padding: 0px;
  align-items: center;
  background-color: $facebookBlue;
  flex-direction: row;
  line-height: 0px;
}

.user_sub_cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: $autumnOrange;
  flex: 2;
}

.user_name_cell {
  font-family: "Lato";
  color: $warmGrey;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.action_cell {
  justify-content: center;
  align-items: center;
  height: 58pt;
  border-left: 1px solid #dedede;
}

.action_cell:hover {
  background-color: $autumnOrange;
}

.more_cell_font {
  font-family: "Lato";
  color: $warmGrey;
  font-size: 7pt;
}

.avatar_image {
  height: 17pt;
}

.table_cell_user {
  display: flex;
  min-width: 140pt;
  flex: 1;
  align-items: center;
}
.table_zipTransfer_cell_action{
  display: flex;
  min-width: 110pt;
  flex: 1;
  align-items: center;

}
.table_zipTransfer_cell_action_placeholder{
  font-weight: bold;
}
.table_cell_item {
  display: flex;
  min-width: 130pt;
  flex: 1;
  align-items: center;
}

.table_cell_image {
  padding: 10pt;
}

.table_cell {
  display: flex;
  min-width: 80pt;
  align-items: center;
  flex: 1;
  padding-left: 14pt;
  border-left: 1px solid $lightGrey;
}
.popup-content .modal{
  max-height: 100vh;
  overflow-y: auto;
  min-height: 600px;
}
.zip_transfer_row .table_cell{
  flex-basis: 25%;
  flex-grow: 0;
  justify-content: center;
}
.zip_transfer_row .table_cell-organization{
  flex-grow: 1;
  width: 100%;
}
.zipTransferInput{
  width: 80%;
}
.dropdown-container{
  display: flex;

  width: 100%;
}
.css-b62m3t-container{
  flex-grow: 1;
}
.allZipsList{
  padding:0;
  justify-content: center;
  flex-wrap: wrap;
  display: flex;
  row-gap: 15px;
  column-gap: 15px;
}
.zipCodes{
  list-style: none;
}
.resetValuePopup {
  display: flex;
  font-size: 30px;
  justify-content: center;
  align-items: center;
}
.deletingConfirmationBtn{
  display: flex;
  justify-content: space-evenly;
}
.deleteAccount{
  color: black;
  font-weight: bold;
  border-color: red;
  background-color: rgba(255, 0, 0, 0.16);
}

.modal_form_field {
  display: flex;
  height: 45pt;
  flex-direction: column;
  margin-bottom: 3%;
  justify-content: space-between;
  margin-top: 3pt;
}

.email_subject {
  display: flex;
  flex-direction: column;
  margin-bottom: 15pt;
  justify-content: space-between;
}

.email_body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 15pt;
}

.email_subject_input {
  display: flex;
  padding-left: 1%;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 15pt;
}

.email_body_input {
  display: flex;
  height: 180pt;
  padding-left: 1%;
  padding-top: 1%;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 15pt;
}

.modal_form_field_label {
  color: $warmGrey;
}

/* FORMS */
.form_section_label {
  font-family: "Lato";
  font-size: 9pt;
}

.form_section_label_bold {
  @extend .form_section_label;
  font-weight: bold;
}

.form_section_container {
  width: 70%;
  margin-bottom: 5pt;
}

.form_section_window {
  border-radius: 0.4rem;
  width: 100%;
  margin-bottom: 1.6rem;
}

.form_section_window textarea {
  border-color: #5db99d;
  width: 100%;
  min-height: 8rem;
  padding: 0.5rem;
  margin-bottom: 1.6rem;
}

.form_field_validation_message {
  padding-top: 3pt;
  font-family: "Lato";
  font-size: 9pt;
  height: 9pt;
}

.no_label_form_field {
  display: flex;
  align-self: center;
  padding-bottom: 5pt;
}

.checkbox {
  align-items: center;
  justify-content: center;
  border: 1px solid white;
}

.checkbox_active {
  @extend .checkbox;
  background-color: $greyishTeal;
}

.checkbox_icon {
  width: 9px;
  height: 9px;
}

.empty_check {
  width: 9pt;
  height: 9pt;
  border: 1px solid $darkGrey;
}

.active_check {
  width: 9px;
  height: 9px;
  background-color: $greyishTeal;
}

.checkContainer {
  display: flex;
  width: 25pt;
  align-items: center;
  justify-content: center;
  padding: 4.5pt;
}

.checkContainer_active {
  @extend .checkContainer;
}

.check_image {
  height: 10pt;
  width: 10pt;
  color: $greyishTeal;
}

.chat_image {
  height: 12pt;
  width: 12pt;
  color: $greyishTeal;
}

.impersonate_cell {
  border-left: 1px solid #dedede;
  display: flex;
  width: 70pt;
  align-items: center;
  justify-content: center;
}

.impersonate_cell:hover {
  cursor: default;
  background-color: #dedede;
}

.button_container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.4rem;
  height: 2.9rem;
  max-width: 12rem;
  min-width: 10rem;
  background-color: $greyishTeal;
}
.btn_add_waiting{
  background-color: red;
}

.button_container[disabled] {
  pointer-events: none;
  opacity: 0.7;
}

.button {
  font-family: "Lato";
  font-size: 1.2rem;
  color: white;
  margin-bottom: 0.2rem;
}

.button_container:active {
  background-color: $greyishTealActive !important;
}

.button_container:hover {
  background-color: $greyishTealHover;
  cursor: default;
}

.large_button_container {
  @extend .button_container;
  display: flex;
  max-height: 70pt;
  min-height: 70pt;
  max-width: 180pt;
  min-width: 180pt;
  align-items: center;
  justify-content: center;
  font-size: larger;
  box-shadow: 2px 2px $background-color;
}

.large_button {
  @extend .button;
  font-size: larger;
}

.radio_cell {
  @extend .table_cell;
}

.radio_cell_inactive {
  @extend .radio_cell;
  background-color: $neutralGrey;
  color: white;
}

.radio_cell_active {
  @extend .radio_cell;
  background-color: $greyishTeal;
  color: white;
}

.radio_cell:hover {
  background-color: $greyishTealHover;
  color: white;
  cursor: pointer;
}

.mark_completed_cell {
  @extend .table_cell;
  cursor: default;
  padding: 10px;
}

.mark_completed_cell_inactive {
  @extend .mark_completed_cell;
  background-color: $neutralGrey;
  color: white;
}

.mark_completed_cell_active {
  @extend .mark_completed_cell;
  background-color: $greyishTeal;
  color: white;
}
.mark_completed_cell_button {
  cursor: pointer;
  display: flex;
  background-color: $lightGrey;
  justify-content: center;
  padding: 5px;
  border-radius: 10px;
  flex: 1;
}
.mark_completed_cell_button:hover {
  background-color: $greyishTealHover;
  color: white;
  cursor: pointer;
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.expanded-table-row {
  padding: 15px;
  opacity: 0;
  animation: fadeIn 1s;
  animation-fill-mode: forwards;
  width: 100%;
  border-bottom: 1px solid $lightGrey;
  display: flex;
  flex-wrap: wrap;
}
.expanded-table-row-col {
  max-width: 50%;
}
.expanded-table-row-col-margin {
  margin: 0px 5rem;
}
.expanded-table-row-col-margin-social {
  display: flex;
}
.expanded-table-row-col-margin-social-link {
  margin-left: 5px;
  cursor: pointer;
  color: #5db99d;
}
/* POPUP */
.popup {
  cursor: pointer;
  background: $greyishTeal;
  padding: 0.2em 0.35em;
  font-size: 12pt;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: white;
  position: relative;
  display: inline-block;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  .popup-text {
    visibility: hidden;
    width: 250px;
    background-color: $greyishTeal;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 8px 8px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -25px;
    font-size: 11pt;

    &.show {
      visibility: visible;
      -webkit-animation: fadeIn 1s;
      animation: fadeIn 1s;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 10%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: $greyishTeal transparent transparent transparent;
    }
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (min-width: 300px) and (max-width: 780px) {
  
  .large_button_container {
      display: flex;
      max-height: 50pt;
      min-height: 40pt;
      max-width: 90pt;
      min-width: 80pt;
      align-items: center;
      justify-content: center;
      font-size: larger;
      box-shadow: 2px 2px #386439;
    
  }

  .ui.scrolling.modal.transition.visible.active { 
    width:350px !important;
  }
  .ui.modal.transition.visible.active {
    width:350px !important;
  }
  .modal_form_field {
    height: 45pt;
    margin-bottom: 5%;
    margin-top: 3pt;
}
}
.zips_select_all{
  flex-direction: column;
}
.zips_select_all p{
  margin-bottom: 5px;
}
.popup-overlay .popup-content{
  width: 70%;
}
.table-zip-row-btns{
  column-gap: 15px;
  padding-left: 0;
}