@import "./theme.scss";

@font-face {
  font-family: "VarelaRound-Regular";
  font-style: normal;
  font-weight: normal;
  src: local("VarelaRound-Regular"),
    url("../assets/fonts/VarelaRound-Regular.ttf") format("ttf");
}

@font-face {
  font-family: "NunitoSans-Regular";
  font-style: normal;
  font-weight: normal;
  src: local("NunitoSans-Regular"),
    url("../assets/fonts/NunitoSans-Regular.ttf") format("ttf");
}

@font-face {
  font-family: "NunitoSans-Bold";
  font-style: normal;
  font-weight: normal;
  src: local("NunitoSans-Bold"),
    url("../assets/fonts/NunitoSans-Bold.ttf") format("ttf");
}

html {
  font-size: 14px;
}

body {
  margin: 0;
  font-family: "NunitoSans-Regular", sans-serif !important;
}

input:disabled {
  opacity: 0.75;
}

* {
  outline: none;
}

.page-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: $background-color;
}

.display-none {
  display: none;
}

.table_cell {
  border: none !important;
}

.width-60 {
  width: 60%;
}

.mb-1 {
  margin-bottom: 1rem;
}