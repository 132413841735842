@import "../../styles/theme.scss";

.nav_bar_container {
  background-color: "green";
  padding: 1.8rem;
}

.nav_link_button {
  border-radius: 4px;
  height: 30pt;
  width: 110pt;
  padding-top: 7pt;
  justify-content: center;
  font-family: "Lato";
  font-size: 12pt;
  text-align: center;
  margin-left: 30pt;
  background-color: $greyishTeal;
  color: white;
}

.nav_link_button:hover {
  cursor: pointer;
}

.nav_modal {
  position: static;
}

.user_header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
}

.avatar_component {
  margin: 0.4rem 0;
}

.avatar_name {
  font-family: "Lato";
  font-weight: bold;
  font-size: medium;
  color: white;
}

.avatar_role {
  font-family: "Lato";
  font-size: small;
  font-style: italic;
  color: $lightGrey;
}

.avatar_logout {
  font-family: "Lato";
  font-weight: bold;
  font-size: smaller;
  color: $greyishTeal;
}

.avatar_logout:hover {
  cursor: pointer;
}

.side_nav_route {
  display: flex;
  flex: 1;
  padding: 8px;
  align-items: center;
  border-bottom: 1px solid $charcoalGrey;
  border-top: 1px solid $charcoalGrey;
}

.side_nav_route_name {
  padding-left: 18px;
  color: $coolGrey;
}

.side_nav_route_name_active {
  @extend .side_nav_route_name;
  color: white;
}

.side_nav_route_active {
  @extend .side_nav_route;
  background-color: $greyishTeal;
}

.table {
  background-color: white;
}

.table_food_weight {
  display: flex;
  background-color: white;
  flex-direction: column;
}

.table_food_weight_footer {
  display: "flex";
  width: 100%;
  align-items: "flex-end";
  justify-content: "flex-end";
}

.modal_footer {
  display: flex;
  width: 100%;
  height: 40pt;
  justify-content: flex-end;
  flex-direction: row;
  padding-right: 5%;
}

.modal_header {
  display: flex;
  padding: 10pt;
  font-family: "Lato";
  font-size: 13.5pt;
  font-weight: bold;
  justify-content: center;
  color: white;
  align-items: center;
  width: 240.5;
  background-color: $greyishTeal;
}

.modal_message_font {
  display: flex;
  padding: 10pt;
  font-family: "Lato";
  font-size: 13.5pt;
}

.modal_content {
  display: flex;
  padding: 18.5pt;
  flex-direction: column;
  flex: 1;
  width: 240.5;
}

.modal_main {
  display: flex;
  flex: 1;
  width: 240.5;
  flex-direction: column;
}

.pickup_window {
  margin-bottom: 10pt;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: white;
  border-top: 0.5rem solid $greyishTeal;
}

.add_edit_schedule_window {
  max-width: 650px;
}

.add_edit_address_window {
  max-width: auto;
}

.pickup_window_content {
  padding: 1.6rem;
}

.table_cell_action {
  display: flex;
  flex: 1;
  padding: 10pt;
  align-items: center;
  justify-content: center;
  border-left: 1px solid $lightGrey;
  border-right: 1px solid $lightGrey;
}

.table_cell_action:hover {
  background: $lightGrey;
}

.email_container {
  padding-bottom: 10%;
}

.login_bottom_container {
  display: flex;
  flex-direction: row;
  color: red;
  justify-content: space-between;
  align-items: center;
}

/* DIALOGUE MODAL */

.content_message {
  display: flex;
}

.contact_info {
  display: flex;
  flex-direction: column;
}

.location_details_row {
  margin-bottom: 1rem;
}

.location_details_container {
  display: flex;
  background-color: white;
  justify-content: space-between;
  flex-direction: column;
}

.social_container {
  display: flex;
  flex-direction: row;
  width: 20vh;
  align-items: center;
  justify-content: space-between;
}

.social_container:hover {
  cursor: pointer;
}

.social_image {
  height: 22pt;
  width: 21pt;
}
.reassign-zip-btn{
  padding-left: 0;
}