// Primary Pallet
$primary: #F0E3D3;
$secondary: #4A8E9B;
$autumnOrange: #d9912a;
$background-color-secondary: #203731;
$background-color: #386439;
$brownGrey: #a1a1a1;
$button-background-color: #386439;
$button-text-color: #ffffff;
$charcoalGrey: #393e41;
$darkGrey: #7a7a7a;
$dirtyOrange: #c57809;
$facebookBlue: #3a599d;
$faded: #808080;
$fadedOrange: #eca949;
$greyishBrown: #4a4a4a;
$greyishTeal: #5db99d;
$greyishTealActive: #3A7462;
$greyishTealHover: #53a68d;
$hospitalGreen: #9bd1a2;
$lightGrey: #9b9b9b;
$neutralGrey: #b0b0b0;
$pale: #edebe9;
$pine: #386439;
$text-color-primary: #000;
$text-color-secondary: #ffffff;
$transparentWhite: rgba(255, 255, 255, 0.75);
// Updates from zeplin
$coolGrey: #96a1a8;
$warmGrey: #7d7d7d;
$lightGrey: #dedede;
$background-side-bar: #23272b;

// Fonts
$varela: VarelaRound-Regular;

:export {
  // colors
  autumnOrange: $autumnOrange;
  backgroundColor: $background-color;
  backgroundColorSecondary: $background-color-secondary;
  brownGrey: $brownGrey;
  buttonBackgroundColor: $button-background-color;
  buttonTextColor: $button-text-color;
  coolGrey: $coolGrey;
  charcoalGrey: $charcoalGrey;
  darkGrey: $darkGrey;
  dirtyOrange: $dirtyOrange;
  facebookBlue: $facebookBlue;
  faded: $faded;
  fadedOrange: $fadedOrange;
  greyishBrown: $greyishBrown;
  greyishTeal: $greyishTeal;
  hospitalGreen: $hospitalGreen;
  lightGrey: $lightGrey;
  neutralGrey: $neutralGrey;
  pale: $pale;
  pine: $pine;
  textColorPrimary: $text-color-primary;
  textColorSecondary: $text-color-secondary;
  transparentWhite: $transparentWhite;
  lightGrey: $lightGrey;
  warmGrey: $warmGrey;

  //fonts
  varela: $varela;
}