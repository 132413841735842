@import "../../styles/theme.scss";

.main_container_section {
  display: flex;
  background-color: $pale;
  flex-direction: column;
  padding: 1.6rem;
}

.main_container_table {
  display: flex;
  background-color: white;
  flex-direction: column;
  margin-bottom: 1.6rem;
}

.table_title {
  font-size: larger;
  font-family: "Lato";
  font-weight: bold;
  color: $warmGrey;
  padding: 0.8rem;
}

// Temporary place for data export classes
.step_container {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-bottom: 1.6rem;
}

.icon_image {
  height: 4rem;
  width: 4rem;
}

.step_row {
  display: flex;
  flex: 1;
  border-top: 0.5rem solid $greyishTeal;
}

.step_row_container {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 1.6rem;
}

.step_description_container {
  margin-left: 1.6rem;
}

.step_label {
  width: 3rem;
  color: white;
  align-self: flex-start;
  font-family: "Lato";
  font-size: large;
  font-weight: bold;
  background-color: $greyishTeal;
  text-align: center;
}

.data_to_export_row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.data_to_export_options_row {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.export_date_range_container {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.seasonal_date_range_container {
  display: flex;
  margin-top: 1rem;
}

.donations_container .table_cell,.mark_completed_cell {
  border-left: none;
}

.export_form_submit_button .ui.button {
  background-color: $greyishTeal;
  color: white;
}

.impersonate_user_container {
  display: flex;
  background-color: #c67709;
  height: 40pt;
  width: 100%;
  font-family: "Lato";
  font-weight: bold;
  font-size: medium;
  color: white;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.impersonate_user_section {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex: 1;
}

.impersonate_user_section_end {
  @extend .impersonate_user_section;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
  padding-right: 3.5%;
  align-self: flex-end;
}

/* SETTINGS SCREEN */

.name_text {
  padding: 1.5%;
  font-family: "Lato";
  font-size: 12pt;
  color: $charcoalGrey;
}

.contact_info_container {
  display: flex;
  background-color: white;
  padding: 2%;
  justify-content: space-between;
  margin-bottom: 1.5%;
  padding-bottom: 1.5%;
  flex-direction: column;
}

.zip_codes_list {
  display: flex;
  flex-direction: column;
}

.additional_zips {
  display: flex;
  flex-direction: column;
}

.toggle_self_service_container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.toggle_self_service_dropoff {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 2em;
}

.toggle_offers_container {
  background-color: white;
  padding: 10px;
  margin-top: 10px;
}

.instructions_button_container {
  display: flex;
  justify-content: space-between;
}

.button_right_container {
  margin-bottom: 2rem;
}

.refresh_container {
  @extend .button_right_container;
  margin-bottom: 1.6rem;
}

.donations_refresh_container {
  @extend .button_right_container;
  margin-bottom: 1.6rem;
  display: flex;
  justify-content: flex-end;
  .button_container {
    cursor: pointer;
  }
}

.update_weights_container {
  display: flex;
  flex-direction: row;
  margin-right: 1.6rem;
}

.self_service_font {
  display: flex;
  font-size: larger;
}

.self_service_font_container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.self_service_font_detail {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3%;
  font-size: medium;
}

.container_divider_row {
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 1700px) {
    flex-direction: column;
  }
}

.card {
  background-color: white;
  display: flex;
  padding: 1.6rem;
  min-height: 50rem;
  align-items: center;
  flex-direction: column;
  min-width: 30rem;
  max-width: 50rem;
  border: 1px solid $greyishTeal;
  border-top: 0.5rem solid $greyishTeal;
  margin-top: 2rem;
  @media only screen and (min-width: 1700px) {
    margin-right: 4rem;
  }
}


.card_title {
  display: flex;
  color: $greyishTeal;
  font-family: "Lato";
  font-weight: bold;
  margin-bottom: 1.6rem;
  font-size: 1.4rem;
}

.card_div {
  display: flex;
  align-items: center;
  width: 280pt;
  flex-direction: column;
  justify-content: space-between;
  height: 520pt;
}

.card_divider {
  margin: 1em;
  height: 1.5pt;
  background-color: $greyishTeal;
  width: 80%;
}

.settings-btn {
  margin-top: 1em;
}
/* Home Screen */

.home_layout {
  display: flex;
  flex-direction: column;
  height: 90vh;
  width: 100%;
}

.buttons_container {
  display: flex;
  align-self: center;
  height: 100%;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  // background-color: rgba(255, 255, 255, 0.3) // background-color: rgba(93, 185, 157, 0.5)
}

.header {
  display: flex;
  width: 100%;
  height: 50pt;
  justify-content: center;
  color: $greyishTeal;
  font-family: "Lato";
  font-size: large;
  font-weight: bold;
}

.top_row_button {
  height: 10pt;
  width: 120pt;
}

.top_row_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header_button {
  position: absolute;
  display: flex;
  background-color: black;
  color: white;
  height: 10vh;
  width: 20vh;
  border-radius: 5pt;
  background-color: $greyishTeal;
  justify-content: center;
  align-items: center;
}

/* SURVEY TABLE */
.table {
  background-color: white;
  margin: 0.5%;
}

.question_header {
  display: flex;
  height: 20pt;
  align-items: center;
  margin-left: 1%;
}

.answer_bar {
  display: flex;
  flex: 1;
}

.answer_true {
  display: flex;
  flex: 1;
  background-color: $greyishTeal;
}

.answer_false {
  display: flex;
  flex: 1;
  flex-direction: row;
  background-color: $autumnOrange;
}

.no_answers {
  display: flex;
  align-items: center;
  color: white;
  font-size: 16;
  flex: 1;
  height: 20pt;
  padding-left: 1%;
  flex-direction: row;
  background-color: $neutralGrey;
}

/* MODAL */

.modal_footer {
  display: flex;
  width: 100%;
  height: 40pt;
  justify-content: flex-end;
  flex-direction: row;
  padding-right: 5%;
}

.modal_header {
  display: flex;
  padding: 10pt;
  font-family: "Lato";
  font-size: 13.5pt;
  font-weight: bold;
  justify-content: center;
  color: white;
  align-items: center;
  width: 240.5;
  background-color: $greyishTeal;
}

.modal_message_font {
  display: flex;
  padding: 10pt;
  font-family: "Lato";
  font-size: 13.5pt;
}

.modal_content {
  display: flex;
  padding: 18.5pt;
  flex-direction: column;
  flex: 1;
  width: 240.5;
}

.modal_main {
  display: flex;
  flex: 1;
  width: 240.5;
  flex-direction: column;
}

.modal_answer_container {
  display: flex;
  flex: 1;
  margin: 2%;
  justify-content: center;
  flex-direction: row;
}

.modal_question {
  display: flex;
  font-weight: bold;
  align-self: center;
}

.modal_divider {
  height: 1pt;
  margin-top: 2%;
  margin-bottom: 2%;
  background-color: $greyishTeal;
  width: 500pt;
  align-self: center;
}

.chat_container {
  // height: 100vh;
  .container {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.container::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.chat-container {
  display: flex;
  justify-content: flex-end;
  margin: 20px;
}

.chat-bubble {
  position: relative;
  border-radius: 10px;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 10px;
}

.sender-bubble {
  background-color: #5cba9c;
  color: #fff;
  align-self: flex-end;
}

.receiver-bubble {
  background-color: #fff;
  color: #333;
  align-self: flex-start;
}

.arrow-right,
.arrow-left {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
}

.arrow-right {
  border-width: 10px 0 10px 15px;
  border-color: transparent transparent transparent #5cba9c;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
}

.arrow-left {
  border-width: 10px 15px 10px 0;
  border-color: transparent #fff transparent transparent;
  left: -15px;
  top: 50%;
  transform: translateY(-50%);
}

::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #FF0000;
}
}