.ui.dimmer {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.centeredLoading {
  text-align: center;
}
.zip_actions_container{
  display: flex;
  width: 100%;
  column-gap:15%;
  margin-top: 3%;
  margin-bottom: 3%;
  justify-content: center;
}
.zip_actions_btn{
  color: #0f0f10;
  font-weight: bold;
  border-color:#09d3ac;
}
.modal {
  font-size: 18px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #09d3ac;
  border-radius: 18px;
  border: 1px solid #09d3ac;
}