@import "../../styles/theme.scss";

.full_page_layout {
  display: flex;
  min-height: 92vh;
}

.side_bar {
  display: flex;
  background-color: $background-side-bar;
  min-width: 20rem;
  flex-direction: column;
}

.main_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $pale;
}

.main_container_header {
  background-color: $greyishTeal;
  color: $button-text-color;
  padding: 1.6rem;
  font-family: "Lato";
  font-weight: bold;
  font-size: 1.6rem;
}
